import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          aboutMe: "About me",
          selectedWork: "Selected Work",
          selectedDesign: "Selected Design",
          sideProject: "Side Project",
          websiteDesign: "Website design & development",
          available: "Available for work",
          lang: "English",
          contactMe: "Contact me via mail",
          allRightsReserved: "All rights reserved",
          thanksVisiting: "Thanks for visiting!",
          aboutPart1:
            "I'm a freelance designer and front-end developer with a passion for crafting beautiful websites and interfaces. Working in close collaboration with an experienced full stack developer, we create complete projects with meticulous attention to detail and an outstanding user experience. Based in France, I'm always open to work on projects or with companies from all around the world.",
          aboutPart2:
            "I'm bringing projects and ideas to life by paying attention to the details and ensuring a great experience for users.",
          unworldDescription:
            "A FiveM community, created in 2021 with a custom framework made from scratch.",
        },
      },
      fr: {
        translation: {
          aboutMe: "A propos",
          selectedWork: "Projets recents",
          selectedDesign: "Design selectionnes",
          sideProject: "Projet Personnel",
          websiteDesign: "Design & développement du site",
          available: "Disponible",
          lang: "Français",
          contactMe: "Contactez-moi par mail",
          allRightsReserved: "Tous droits réservés",
          thanksVisiting: "Merci de votre visite !",
          aboutPart1:
            "Je suis un designer et un développeur front-end freelance passionné par la création de sites et d'interfaces sur-mesure. En étroite collaboration avec un développeur full stack expérimenté, nous travaillons en symbiose pour réaliser des projets complets, en accordant une attention particulière aux détails et à l'expérience des utilisateurs. Basé en France, je suis prêt à travailler avec des entreprises du monde entier.",
          aboutPart2:
            "Je donne vie à des projets et des idées en prêtant attention aux détails et en garantissant une excellente expérience pour les utilisateurs.",
          unworldDescription:
            "Communauté FiveM, créée en 2021 avec un framework custom.",
        },
      },
    },
    lng: "fr",
  });

export default i18next;
