import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewLink from "./components/icons/new-link";

import "./styles/main.min.css";
import { designs } from "./data/designs";

function App() {
  const { t, i18n } = useTranslation();
  const [designSelected, setDesignSelected] = useState(-1);

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  const changeLanguage = () => {
    if (
      i18n.language === "fr" ||
      i18n.language === "fr-FR" ||
      i18n.language === "fr-CA"
    ) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  };

  const selectDesign = (img) => {
    setDesignSelected(img);
  };

  return (
    <>
      <img id="mainLight" src="./main_light.png" alt="Texturing light" />
      <header className="mb-52">
        <img src="./lynx.svg" alt="Logo 2024 blanc" />
        <div className="flex items-center gap-6">
          <span onClick={changeLanguage} className="flex items-center gap-2">
            {t("lang")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#FDFAF9"
                fillRule="evenodd"
                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-4.109-7.75a12.348 12.348 0 0 1-.075-4.5H3.8A8.51 8.51 0 0 0 3.5 12c0 .779.105 1.533.301 2.25h4.09Zm.39 1.5H4.37a8.51 8.51 0 0 0 6.251 4.639c-1.118-1.62-1.872-3.17-2.34-4.639Zm1.583 0h4.272c-.451 1.255-1.14 2.588-2.136 3.993-.996-1.405-1.685-2.738-2.136-3.993Zm4.711-1.5h-5.15a10.878 10.878 0 0 1-.085-4.5h5.32a10.878 10.878 0 0 1-.085 4.5Zm1.145 1.5c-.47 1.47-1.223 3.02-2.341 4.639a8.51 8.51 0 0 0 6.251-4.639h-3.91Zm4.479-1.5h-4.09a12.35 12.35 0 0 0 .075-4.5H20.2a8.51 8.51 0 0 1 .3 2.25 8.51 8.51 0 0 1-.301 2.25ZM12 4.182c-.758.97-1.674 2.347-2.26 4.068h4.52c-.586-1.721-1.502-3.099-2.26-4.068Zm3.835 4.068h3.795a8.511 8.511 0 0 0-6.168-4.625c.832 1.12 1.795 2.684 2.373 4.625Zm-11.465 0h3.795c.578-1.94 1.54-3.505 2.373-4.625A8.511 8.511 0 0 0 4.37 8.25Z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <a href="#about-me">Contact</a>
        </div>
      </header>
      <main className="flex flex-col justify-center gap-36">
        <section
          id="hero"
          className="text-center font-medium text-[1.65rem] md:text-[2.5rem]"
        >
          {i18n.language === "fr" ||
          i18n.language === "fr-FR" ||
          i18n.language === "fr-CA" ? (
            <>
              Bienvenue, je suis
              <span className="magnifique">Erwann</span>
              <br />
              <span>Designer</span> et
              <span> développeur front end freelance</span>
              <br />
              Du sud de la{" "}
              <span id="france">
                France <img src="./fr_flag.png" alt="Drapeau de la France" />
              </span>
            </>
          ) : (
            <>
              Hi, I&apos;m <span className="magnifique">Erwann</span>
              <br />A <span>Freelance Designer</span> and{" "}
              <span>Front End developer</span> <br />
              From{" "}
              <span id="france">
                France <img src="./fr_flag.png" alt="Drapeau de la France" />
              </span>
            </>
          )}
        </section>
        <section id="about-me" className="flex flex-col gap-12 items-center">
          <h2 className="rotate-[-2deg]">{t("aboutMe")}</h2>
          <div className="w-full flex flex-col items-center md:items-start md:flex-row gap-12">
            <img src="./profile.png" alt="Photo de profile" />
            <div className="flex flex-col gap-12">
              <p className="text-lg font-normal">
                {t("aboutPart1")}
                <br />
                <br />
                {t("aboutPart2")}
              </p>
              <div id="about-available" className="flex items-center gap-3">
                <div>
                  <span></span> {t("available")}
                </div>
                <a href="mailto:contact@lynxd.me" target="_blank">
                  {t("contactMe")} <NewLink />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          id="selected-work"
          className="w-full flex flex-col gap-12 items-center"
        >
          <h2 className="rotate-[1deg]">{t("selectedWork")}</h2>
          <div className="w-full flex flex-col gap-12">
            <a
              href="https://conceptferreira.com"
              target="_blank"
              id="concept-ferreira"
              className="work-card"
            >
              <div className="wrapper">
                <div className="content">
                  <div>
                    <h3>Concept Ferreira</h3>
                    <p>2024</p>
                  </div>
                  <p>{t("websiteDesign")}</p>
                </div>
                <img src="./project_preview2.png" alt="Oui" />
              </div>
              <div className="bg-noise"></div>
            </a>
            <a
              href="https://intraced.framer.website"
              target="_blank"
              id="intraced"
              className="work-card"
            >
              <div className="wrapper">
                <div className="content">
                  <div>
                    <h3>Intraced</h3>
                    <p>2023</p>
                  </div>
                  <p>{t("websiteDesign")}</p>
                </div>
                <img src="./project_preview.png" alt="Oui" />
              </div>
              <div className="bg-noise"></div>
            </a>
          </div>
        </section>
        <section
          id="selected-design"
          className="w-full flex flex-col gap-12 items-center"
        >
          <h2 className="rotate-[-1deg]">{t("selectedDesign")}</h2>
          <div className="w-full flex flex-wrap gap-3">
            {designs.map((item, idx) => {
              return (
                <div
                  className={`design-card ${item.color ? item.color : ""}`}
                  onClick={() => selectDesign(idx)}
                  key={idx}
                >
                  <div className="wrapper">
                    <div className="content">
                      <h3>{item.title}</h3>
                      <p>{item.year}</p>
                    </div>
                    <img src={item.thumb} />
                  </div>
                  <div className="bg-noise"></div>
                </div>
              );
            })}
          </div>
        </section>
        {designSelected !== -1 && (
          <div className="design-overview">
            <h3>{designs[designSelected].title}</h3>
            <img src={designs[designSelected].img} alt="" />
            <p>{designs[designSelected].text}</p>
            {designs[designSelected].images.length > 0 && (
              <div className="images">
                {designs[designSelected].images.map((url, idx) => {
                  return <img src={url} key={idx} alt="" />;
                })}
              </div>
            )}
            <button className="close" onClick={() => selectDesign(-1)}>
              Fermer
            </button>
          </div>
        )}
      </main>
      <footer className="text-center font-medium flex flex-col items-center mt-52">
        <p className="text-xl mb-3">{t("thanksVisiting")}</p>
        <img src="./signature.png" alt="" />
        <p className="text-xs mt-24">
          &copy; 2024 Lynx. {t("allRightsReserved")}.
        </p>
      </footer>
    </>
  );
}

export default App;
