export default function NewLink() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        fill="#0A0A0A"
        d="M8.003 7.41a.6.6 0 0 0 .6-.6V2.432a.622.622 0 0 0-.622-.622H3.603a.6.6 0 0 0 0 1.2h2.493c.172 0 .258.208.137.33L1.824 7.749a.6.6 0 1 0 .849.848l4.401-4.402a.192.192 0 0 1 .329.136V6.81a.6.6 0 0 0 .6.6Z"
      />
    </svg>
  );
}
